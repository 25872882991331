import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

// Plugins
import i18n from './plugins/vue-i18n';
import vuetify from './plugins/vuetify.ts';
import './plugins/leaflet';
import './plugins/vee-validate.ts';

// STYLES
// Main Theme SCSS
import '../sass/theme.scss';

// App SCSS
import '../sass/app.scss';
import { createPinia } from 'pinia';

createInertiaApp({
  title: (title) => `${title} - Partner Portal`,
  resolve: (name) =>
    // @ts-ignore
    resolvePageComponent(`./pages/${name}.vue`, import.meta.glob('./pages/**/*.vue')),
  setup({ el, App, props, plugin }) {
    createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(i18n)
      .use(createPinia())
      .use(vuetify)
      .mount(el);
  },
}).then();
