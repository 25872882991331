import { SubscriptionStatus } from '../../../services/administration/subscription-status.ts';

const statuses: { [key in SubscriptionStatus]: string } = {
  active: 'Actief',
  cancelled: 'Beëindigd',
  created: 'Aangemaakt',
  upcoming: 'Aankomend',
};

export const SubscriptionMessages = {
  title: 'Abonnement {label}',
  description:
    'Dit overzicht toont alle jullie abonnementen. ' +
    'Je kunt hier de status van je abonnementen bekijken. ' +
    'Ga naar de postcode check pagina om een nieuw abonnement af te sluiten.',
  search: 'Zoek op adres, ordernummer, beschrijvingen en abonnement regel IDs',

  hint: {
    ppu: 'De prijs na toepassing van (staffel)kortingen en indexaties. Dit kan afwijken van de prijs in de order.',
  },
  properties: {
    amount: 'Aantal',
    description: 'Omschrijving',
    dateEnd: 'Einddatum',
    dateStart: 'Startdatum',
    id: 'ID',
    initialPeriod: 'Contracttermijn (maanden)',
    nextInvoiceDate: 'Volgende factuurdatum',
    orderNumber: 'Ordernummer',
    ppu: 'Nettoprijs per eenheid',
    recurrence: 'Facturatieperiode',
  },
  lineItems: 'Abonnementsregels',
  recurrence: 'Per {months} maand',
  statuses,
};
