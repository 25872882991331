import { ContactDealAssociationType } from '../../services/hubspot/contact-deal-association-type.enum.ts';

const dealAssociationTypeMessages: { [key in ContactDealAssociationType]: string } = {
  contact_on_location: 'Contact op locatie',
  partner_general: 'Communicatie',
  partner_owner: 'Aanvrager',
};

export const ContactMessages = {
  title: 'Contactpersoon',
  existing: 'Bestaand contactpersoon',
  new: 'Nieuw contactpersoon',
  search: 'Zoek contactpersoon',

  dealAssociationTypes: dealAssociationTypeMessages,
  name: 'Naam',
  properties: {
    email: 'E-mail',
    firstname: 'Voornaam',
    lastname: 'Achternaam',
    phone: 'Telefoonnummer',
  },
};
