export const AuthMessages = {
  backToLogin: 'Terug naar inloggen',
  login: 'Login',
  logout: 'Uitloggen',

  account: {
    manage: 'Account beheren',
  },
  confirmPassword: {
    description: 'Bevestig je wachtwoord voordat je verder gaat.',
    title: 'Bevestig je wachtwoord',
  },
  emailVerification: {
    title: 'E-mailadres verifiëren',
    description:
      'Voordat je verder gaat, kun je je e-mailadres verifiëren door te klikken op de link die we je zojuist hebben gemaild? Als je de e-mail niet hebt ontvangen, sturen we je graag een andere.',
    linkSent:
      'Er is een nieuwe verificatielink naar het e-mailadres gestuurd dat je hebt opgegeven in je profielinstellingen.',
    resend: 'Verstuur nieuw verificatie e-mail',
  },
  forgotPassword: {
    title: 'Wachtwoord vergeten?',
    explanation:
      'Wachtwoord vergeten? Geen probleem. Vul je e-mailadres in en we sturen je een e-mail met instructies om je wachtwoord opnieuw in te stellen.',
  },
  password: {
    edit: 'Wachtwoord wijzigen',
    editDescription:
      'Zorg ervoor dat je een lang, willekeurig wachtwoord gebruikt om je account te beveiligen.',
    update: 'Wachtwoord bijwerken',
    updated: 'Wachtwoord succesvol gewijzigd',
  },
  passwordConfirmation: {
    title: 'Bevestig je wachtwoord',
    description: 'Bevestig eerst je wachtwoord voordat je verder gaat.',
  },
  profile: {
    emailMustBeVerified:
      'Een nieuw e-mailadres moet worden geverifieerd voordat het kan worden gebruikt.',
    title: 'Profiel informatie',
    update: 'Profiel bijwerken',
    updated: 'Profiel succesvol bijgewerkt',
  },
  resetPassword: 'Reset wachtwoord',
  twoFactorAuthentication: {
    title: 'Twee-factor authenticatie',
    description:
      'Voeg extra beveiliging toe aan je account met behulp van twee-factor authenticatie. Wanneer deze is ingeschakeld, wordt je gevraagd om een beveiligingscode in te voeren die naar je telefoon wordt gestuurd wanneer je inlogt.',
    enabled: 'Twee-factor authenticatie is ingeschakeld voor je account.',
    finishEnabling: 'Voltooien inschakelen twee-factor authenticatie',
    notEnabled: 'Twee-factor authenticatie is niet ingeschakeld voor je account.',
    notEnabledWarning: {
      title: 'Schakel twee-factor authenticatie in om je account beter te beveiligen.',
      text: 'Wanneer twee-factor authenticatie is ingeschakeld, wordt je gevraagd om een beveiligingscode in te voeren die naar je telefoon wordt gestuurd wanneer je inlogt.',
    },
    recoveryCodes:
      'Bewaar deze herstelcodes in een veilige wachtwoordmanager. Ze kunnen worden gebruikt om toegang tot je account te herstellen als je apparaat voor twee-factor authenticatie verloren gaat.',
    regenerateRecoveryCodes: 'Herstelcodes opnieuw genereren',
    setupAndConfirm:
      'Om de twee-factor authenticatie in te schakelen, scan je de volgende QR-code met je telefoon of voer je de setup-sleutel in en geef je de gegenereerde OTP-code op.',
    setupKey: 'Setup-sleutel',
    showRecoveryCodes: 'Toon herstelcodes',
  },
  twoFactorChallenge: {
    title: 'Twee-factor bevestiging',
    confirmAuthenticationCode:
      'Bevestig toegang tot je account door de verificatiecode in te voeren die door je authenticator-applicatie is verstrekt.',
    confirmRecoveryCode:
      'Bevestig toegang tot je account door een van je herstelcodes in te voeren.',
    useAuthenticationCode: 'Gebruik verificatiecode',
    useRecoveryCode: 'Gebruik herstelcode',
  },

  field: {
    currentPassword: 'Huidig wachtwoord',
    email: 'E-mail',
    name: 'Naam',
    password: 'Wachtwoord',
    passwordConfirmation: 'Wachtwoord bevestiging',
    recoveryCode: 'Herstelcode',
    rememberMe: 'Onthoud mij',
  },
  requestPasswordResetLink: 'Vraag wachtwoord reset link aan',
  welcome:
    'Welkom bij de Partner Portal van Fore Freedom. Log in om toegang te krijgen tot de portal.',
};
