import { PartnerType } from '../../services/hubspot/partner-type.enum.ts';

const PartnerTypeMessages: { [key in PartnerType]: string } = {
  distributor: 'Distributeur',
  wholesale_internet: 'Wholesale internet',
  wholesale_isp: 'Wholesale ISP',
};

export const CompanyMessages = {
  details: 'Bedrijfsgegevens',

  communicationContacts: {
    edit: 'Bewerk communicatiecontacten',
    explanation: 'Contacten die in de CC van emails over je bestellingen worden toegevoegd',
    none: 'Geen communicatiecontacten ingesteld',
    title: 'Communicatiecontacten',
  },

  partnerType: PartnerTypeMessages,

  property: {
    domain: 'Domeinnaam',
    partnerType: 'Type partner',
  },
};
