import { CustomerType } from '../../../services/administration/customer-type.enum.ts';

const CompanyTypeMessages: { [key in CustomerType]: string } = {
  0: 'Klant',
  1: 'Reseller',
  2: 'Wholesale Internet',
  3: 'Wholesale ISP',
  4: 'Distributeur',
};

export const CustomerMessages = {
  title: 'Klant',
  owner: 'Eigenaar',

  properties: {
    debtorNumber: 'Debiteurnummer',
    name: 'Klantnaam',
    owner: 'Eigenaar',
    type: 'Klanttype',
  },
  types: CompanyTypeMessages,
};
