import { CommonMessages } from './common.messages.ts';
import { AuthMessages } from './auth.messages.ts';
import { CompanyMessages } from './company.messages.ts';
import { UserMessages } from './user.messages.ts';
import { ContactMessages } from './contact.messages.ts';
import { AddressMessages } from './address.messages.ts';
import { OrderMessages } from './order.messages.ts';
import { DealMessages } from './deal.messages.ts';
import { ProductMessages } from './product.messages.ts';
import { TicketMessages } from './ticket.messages.ts';
import { MaintenanceOutageMessages } from './maintenance-outage.messages.ts';
import { AdministrationMessages } from './administration';

export default {
  document: {
    important: 'Belangrijke documenten',
    sla: 'SLA',
    termsAndConditions: 'Algemene voorwaarden',
  },
  orders: 'Orders',
  products: 'Producten',
  support: 'Support',
  tickets: 'Tickets',

  address: AddressMessages,
  administration: AdministrationMessages,
  auth: AuthMessages,
  common: CommonMessages,
  company: CompanyMessages,
  contact: ContactMessages,
  deal: DealMessages,
  maintenanceOutage: MaintenanceOutageMessages,
  order: OrderMessages,
  product: ProductMessages,
  ticket: TicketMessages,
  user: UserMessages,
};
