import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import { nl } from 'vuetify/locale';
import theme from '../configs/theme.ts';

/**
 * Vuetify Plugin
 * Main components library
 *
 * https://vuetifyjs.com/
 *
 */
export default createVuetify({
  defaults: {
    VBtn: {
      variant: 'tonal',
    },
    VCard: {
      variant: 'flat',
    },
    VCardActions: {
      VBtn: { variant: 'flat' },
    },
  },
  icons: {
    defaultSet: 'mdi',
  },
  locale: {
    locale: 'nl',
    messages: { nl },
  },
  theme: {
    defaultTheme: theme.globalTheme,
    themes: {
      dark: theme.dark,
      light: theme.light,
    },
  },
});
