export const AddressMessages = {
  title: 'Address',

  houseNumber: 'Huisnummer',
  houseNumberExtension: 'Huisnummertoevoeging',
  postalCode: 'Postcode',

  check: {
    postalCode: 'Postcode check',
    offNetWarning: {
      contact: 'Neem contact op voor meer informatie over de mogelijkheden.',
      text: 'Het kan zijn dat het te verweg ligt van ons netwerk of de route significante kunstwerken kruist.',
      title: 'Er zijn helaas geen producten beschikbaar.',
    },
  },

  requestBundelingActive:
    'In dit gebied is een vraagbundeling actief. U kunt bestellen, echter bij te weinig animo is het mogelijk dat wij de bestelling annuleren.',

  hint: {
    houseNumberExtensions:
      'Gevonden bestaande toevoegingen voor postcode-huisnummer combi: {extensions}',
  },
};
