import { CustomerMessages } from './customer.messages.ts';
import { SubscriptionMessages } from './subscription.messages.ts';
import { InvoiceMessages } from './invoice.messages.ts';

export const AdministrationMessages = {
  title: 'Administratie',
  invoices: 'Facturen',
  subscriptions: 'Abonnementen',

  customer: CustomerMessages,
  invoice: InvoiceMessages,
  subscription: SubscriptionMessages,
};
