export const UserMessages = {
  create: 'Gebruiker toevoegen',
  delete: {
    success: 'Gebruiker {name} is verwijderd',
    text: 'Weet je zeker dat je deze gebruiker wilt verwijderen? Typ de naam van de gebruiker om te kunnen bevestigen.',
    title: 'Gebruiker {name} verwijderen',
  },
  edit: 'Gebruiker {name} bewerken',
  list: 'Gebruikers',

  emailVerified: 'E-mail geverifieerd',
  role: 'Rol',
  twoFactorAuthenticated: 'Twee-factor-authenticatie ingeschakeld',
  updated: 'Gebruiker {name} is bijgewerkt',
  created: 'Gebruiker {name} is toegevoegd',
};
